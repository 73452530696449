/* /node_modules/typeface-roboto/files/roboto-regular.woff2 */
@font-face {
  font-family: 'Roboto';
  src: url('/node_modules/typeface-roboto/files/roboto-latin-300.woff2') format('woff2'),
       url('/node_modules/typeface-roboto/files/roboto-latin-300.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

/* Reset de estilos globais */
:root {
  --primary-color: #c0810d;
  --secondary-color: #78884c;
  --background-color: #ccc;
  --text-color: #222;
  --ion-background-color: #f5f5f5; /* Sugestão de definição */
}

body,
html {
  font-family: 'Roboto', sans-serif;
  font-size: 14px;
  background: var(--ion-background-color);
  color: var(--text-color);
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  position: fixed;
  width: 100%;
  max-width: 100%;
  height: 100%;
  max-height: 100%;
  text-rendering: optimizeLegibility;
  overflow: auto;
  touch-action: manipulation;
  -webkit-user-drag: none;
  -ms-content-zooming: none;
  word-wrap: break-word;
  -webkit-text-size-adjust: none;
  -moz-text-size-adjust: none;
  -ms-text-size-adjust: none;
  text-size-adjust: none;
  margin: 0;
  padding: 0;
  margin-bottom: 5%;
  overflow-x: hidden;
}

.overlay {
  padding: 0.625rem;
}

/* Estilos dos Títulos */
h1,
h2,
h3,
h4 {
  position: relative;
}

/* Ajustar tamanhos para dispositivos móveis */
@media only screen and (max-width: 600px) {
  h1 {
    font-size: 20px;
  }

  h2 {
    font-size: 18px;
  }

  h3 {
    font-size: 16px;
  }

  h4 {
    font-size: 14px;
  }
}

/* Ajustar tamanhos para telas maiores */
@media only screen and (min-width: 601px) {
  h1 {
    font-size: 24px;
  }

  h2 {
    font-size: 22px;
  }

  h3 {
    font-size: 20px;
  }

  h4 {
    font-size: 18px;
  }
}

h1, h2, h3, h4, h5, h6 {
  color: var(--secondary-color)!important;
}


/* Linha abaixo dos Títulos */
h1::after,
h2::after,
h3::after,
h4::after {
  content:"";
  position:absolute;
  bottom:-5px;
  left: 0;
  width: 50%;
  height: 2px;
  background-color: #c0810d;
}

a {
  color: var(--secondary-color);
  text-decoration: none;
}

a:hover {
  color: var(--primary-color);
}

a:focus {
  outline: 2px dashed var(--primary-color);
  outline-offset: 4px;
}

img {
  height: auto;
  width: 100%;
}

a img {
  width: 300px;
  transition: filter 0.3s ease-in-out, opacity 0.3s ease-in-out, border-color 0.3s ease-in-out;
}

a img:hover {
  filter: blur(3px);
  opacity: 0.8; 
}


.Container>* {
  flex-grow: 1;
  flex-shrink: 0;
}
